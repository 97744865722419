import ChefToken from "@/components/ChefToken";
import Thumbnail from "@/src/components/thumbnail";
import clsx from "clsx";

const CollectionThumbnail = ({
  entry,
  onClick = () => {},
  priority = false,
  disableLink = false,
}) => {
  const chef = entry.chefs?.[0];
  const image = entry.image?.[0];
  const href = `/${entry?.uri || ""}`;

  const handleClick = () => {
    onClick();
  };

  return (
    <Thumbnail.Root theme="dark" onClick={handleClick}>
      <Thumbnail.Image
        src={image?.url}
        alt={image?.title}
        priority={priority}
        href={!disableLink && !!href ? href : undefined}
      >
        {entry.recipeCount > 0 && (
          <div
            className={clsx("absolute left-4 top-4 font-body text-zinc-950")}
          >
            <div
              className={clsx(
                "h-component-sm flex items-center justify-center rounded-full bg-white/85 px-2.5 font-body text-sm backdrop-blur-lg"
              )}
            >
              {entry.recipeCount} recipes
            </div>
          </div>
        )}
      </Thumbnail.Image>

      <Thumbnail.Caption>
        <Thumbnail.Header href={!disableLink && !!href ? href : undefined}>
          {entry.title}
        </Thumbnail.Header>
      </Thumbnail.Caption>

      <Thumbnail.Footer>
        <ChefToken chef={chef} isMob={!chef} size="xs" theme="dark" />
      </Thumbnail.Footer>
    </Thumbnail.Root>
  );
};

export default CollectionThumbnail;
