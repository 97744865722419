import OldEntryThumbnail from "@/components/EntryThumbnail";
import CollectionThumbnail from "@/src/collections/components/Thumbnail";
import RecipeLoadingThumbnail from "@/src/components/LoadingThumbnail";
import MealPlanCollectionThumbnail from "@/src/mealPlanCollections/components/Thumbnail";
import MealPlanThumbnail from "@/src/mealPlans/components/Thumbnail";
import RecipeThumbnail from "@/src/recipes/components/Thumbnail";

type OldEntryThumbnailProps = React.ComponentProps<typeof OldEntryThumbnail>;
type RecipeThumbnailProps = React.ComponentProps<typeof RecipeThumbnail>;
type MealPlanThumbnailProps = React.ComponentProps<typeof MealPlanThumbnail>;
type CollectionThumbnailProps = React.ComponentProps<
  typeof CollectionThumbnail
>;
type MealPlanCollectionThumbnailProps = React.ComponentProps<
  typeof MealPlanCollectionThumbnail
>;

type EntryThumbnailProps = OldEntryThumbnailProps &
  RecipeThumbnailProps &
  MealPlanThumbnailProps &
  CollectionThumbnailProps &
  MealPlanCollectionThumbnailProps & {
    entry: {
      sectionHandle:
        | "recipes"
        | "mealPlans"
        | "collections"
        | "mealPlanCollections"
        | string;
    };
  };

const EntryThumbnail = (props: EntryThumbnailProps) => {
  // If there is no entry, or the "entry" is loading, use the loading thumbnail
  if (!props.entry || props.entry.sectionHandle === "loading") {
    return <RecipeLoadingThumbnail />;
  }

  // If the entry is a recipe, use the new thumbnail component
  if (props.entry.sectionHandle === "recipes") {
    return <RecipeThumbnail {...props} />;
  }

  // If the entry is a meal plan, use the new meal plan thumbnail component
  if (props.entry.sectionHandle === "mealPlans") {
    return <MealPlanThumbnail {...props} />;
  }

  // If the entry is a collection, use the new collection thumbnail component
  if (props.entry.sectionHandle === "collections") {
    return <CollectionThumbnail {...props} />;
  }

  if (props.entry.sectionHandle === "mealPlanCollections") {
    return <MealPlanCollectionThumbnail {...props} />;
  }

  return <OldEntryThumbnail {...props} />;
};

export default EntryThumbnail;
