import getSectionNameFromHandle from "@/lib/getSectionNameFromHandle";
import {
  CalendarDaysIcon,
  NewspaperIcon,
  RectangleStackIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import Link from "next/link";

const ICONS = {
  articles: NewspaperIcon,
  mealPlans: CalendarDaysIcon,
  collections: RectangleStackIcon,
  products: ShoppingBagIcon,
  mealPlanCollections: RectangleStackIcon,
  articleCollections: RectangleStackIcon,
};

const SectionToken = ({
  sectionHandle = "",
  size = "sm",
  href = null,
  theme = "dark",
}: {
  sectionHandle?: string;
  size?: "sm" | "md";
  withBackground?: boolean;
  href?: string;
  theme?: "dark" | "light";
}) => {
  const className = clsx("group flex items-center", {});

  const _Wrapper = ({ children }) => {
    if (!!href) {
      return (
        <Link href={href}>
          <a className={className}>{children}</a>
        </Link>
      );
    }

    return <div className={className}>{children}</div>;
  };

  const Icon = ICONS[sectionHandle] || null;

  return (
    <_Wrapper>
      <div
        className={clsx(
          "relative flex aspect-square items-center justify-center overflow-hidden rounded-full ",
          {
            "h-6 w-6": size === "sm",
            "w-component h-component": size === "md",
            "bg-zinc-200 text-zinc-800": theme === "dark",
            "bg-zinc-800 text-zinc-200": theme === "light",
          }
        )}
      >
        <div
          className={clsx("flex items-center justify-center", {
            "h-3 w-3": size === "sm",
            "h-10 w-10": size === "md",
          })}
        >
          {!!Icon && <Icon className="h-full w-full" />}
        </div>
      </div>
      <div
        className={clsx(
          "font-body text-current opacity-80 transition ease-out group-hover:opacity-100",
          {
            "px-2 text-sm": size === "sm",
            "px-2.5 text-sm": size === "md",
          }
        )}
      >
        {getSectionNameFromHandle(sectionHandle)}
      </div>
    </_Wrapper>
  );
};

export default SectionToken;
