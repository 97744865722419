import clsx from "clsx";
import { ReactNode } from "react";

export type SummaryProps = {
  children: ReactNode;
};

const Summary = ({ children }: SummaryProps): JSX.Element => {
  return (
    <div className={clsx("px-4")}>
      <div
        className={clsx(
          "line-clamp-3 font-body text-sm font-normal text-gray-600 @3xs:line-clamp-4 @2xs:line-clamp-4 @xs:line-clamp-5"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Summary;
