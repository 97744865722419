import { Check } from "components/Icons";
import DayJs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";

DayJs.extend(relativeTime);

const SaveStatus = ({ recipe }) => {
  return (
    <div className="SaveStatus">
      <div className="SaveStatus__icon">
        <Check />
      </div>
      <div className="SaveStatus__content">
        {recipe.menu.isDefault && "Saved "}
        {!recipe.menu.isDefault && (
          <>
            Saved to{" "}
            <Link href={recipe.menu.link.href} as={recipe.menu.link.as}>
              <a>{recipe.menu.title}</a>
            </Link>{" "}
          </>
        )}
        {DayJs(recipe.dateAdded).fromNow()}
      </div>
    </div>
  );
};

export default SaveStatus;
