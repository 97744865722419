import clsx from "clsx";
import { ReactNode } from "react";

export type CaptionProps = {
  children: ReactNode;
};

const Caption = ({ children }: CaptionProps): JSX.Element => {
  return (
    <div className={clsx("flex grow flex-col py-2.5")}>
      <div className={clsx("flex flex-col space-y-1")}>{children}</div>
    </div>
  );
};

export default Caption;
