import clsx from "clsx";
import { ReactNode } from "react";

export type ImagePlaceholderProps = {
  children?: ReactNode;
};

const ImagePlaceholder = (props: ImagePlaceholderProps): JSX.Element => {
  const { children, ...imagePlaceholderProps } = props;

  return <div className={clsx("relative aspect-square bg-zinc-100")}></div>;
};

export default ImagePlaceholder;
