import Loading from "@/components/Loading";
import Thumbnail from "@/src/components/thumbnail";
import clsx from "clsx";

const RecipeLoadingThumbnail = ({}) => {
  return (
    <Thumbnail.Root theme="dark">
      <Thumbnail.ImagePlaceholder />

      <Thumbnail.Caption>
        <Thumbnail.Header>
          <div className={clsx("py-2")}>
            <Loading color="#09090b" size={16} />
          </div>
        </Thumbnail.Header>
      </Thumbnail.Caption>
    </Thumbnail.Root>
  );
};

export default RecipeLoadingThumbnail;
