import clsx from "clsx";
import { ReactNode } from "react";

export type FooterProps = {
  children: ReactNode;
};

const Footer = ({ children }: FooterProps): JSX.Element => {
  return (
    <div
      className={clsx("mt-auto flex-col px-4 font-body text-sm text-gray-600")}
    >
      <div className={clsx("w-full border-t py-3")}>
        <div className="flex items-center justify-between">{children}</div>
      </div>
    </div>
  );
};

export default Footer;
