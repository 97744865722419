import { shortEn } from "@/lib/constants";
import { ClockIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import humanizeDuration from "humanize-duration";

humanizeDuration.languages.shortEn = shortEn;

interface TimeIndicatorProps {
  time: number;
}

const TimeIndicator = ({ time }: TimeIndicatorProps) => {
  const humanizedTime = humanizeDuration(time * 60000, {
    largest: 1,
    units: ["h", "m"],
    round: true,
    language: "shortEn",
  });

  return (
    <div
      className={clsx(
        "flex items-center space-x-1 whitespace-nowrap font-body text-xs text-zinc-500"
      )}
    >
      <ClockIcon className={clsx("size-4")} />
      <div>
        {humanizedTime}
        <span className={clsx("hidden select-none @3xs:inline")}> cook</span>
      </div>
    </div>
  );
};

export default TimeIndicator;
