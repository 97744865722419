import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/image";
import { useEffect, useState } from "react";

const ImageFan = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleMouseMove = (e) => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - left;
    const active = Math.floor((mouseX / width) * images.length);
    setActiveIndex(active);
  };

  useEffect(() => {
    // Reset active index when images change
    setActiveIndex(-1);
  }, [images]);

  return (
    <div
      className={clsx("group absolute inset-0")}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setActiveIndex(-1)}
    >
      {images.map((recipeImage, index) => {
        return (
          <div key={recipeImage.id} className={clsx("absolute inset-10")}>
            <div
              className={clsx(
                "absolute inset-0 z-10 overflow-hidden rounded-3xl shadow-lg transition ease-out",
                {
                  "z-20": index === activeIndex,
                  "-translate-x-4 -rotate-[5deg] group-hover:-translate-x-6 group-hover:-rotate-[6deg]":
                    index === 0,
                  "translate-x-6 rotate-[5deg] group-hover:translate-x-8 group-hover:rotate-[6deg]":
                    index === 2,
                }
              )}
            >
              <Image
                src={recipeImage.url}
                layout="fill"
                objectFit="cover"
                alt={recipeImage.title}
                sizes="(max-width: 768px) 100vw, (max-width: 1540px) 33.333vw, 20vw"
                className={clsx(
                  "EntryThumbnail__image",
                  "transition-transform ease-out"
                )}
                loader={cloudflareLoader}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ImageFan;
