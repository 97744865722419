import clsx from "clsx";
import Link from "next/link";
import { ReactNode } from "react";

export type HeaderProps = {
  children: ReactNode;
  className?: string;
  href?: string;
};

const Header = ({
  children,
  className,
  href,
  ...props
}: HeaderProps): JSX.Element => {
  const finalClassName = clsx(
    "px-4 font-body leading-tight text-zinc-800 text-sm @2xs:text-base transition ease-out",
    {
      "hover:opacity-75": !!href,
    },
    className
  );

  if (href) {
    return (
      <Link href={href} prefetch={false}>
        <a {...props} className={finalClassName}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <div {...props} className={finalClassName}>
      {children}
    </div>
  );
};

export default Header;
