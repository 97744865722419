import clsx from "clsx";
import { ReactNode } from "react";

export type MetaProps = {
  children: ReactNode;
};

const Meta = ({ children }: MetaProps): JSX.Element => {
  return (
    <div
      className={clsx(
        "mt-auto flex items-center space-x-3 px-4 font-body text-zinc-800"
      )}
    >
      {children}
    </div>
  );
};

export default Meta;
