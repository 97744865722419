const PurchaseLinks = ({ entry }) => {
  const { purchaseLinks } = entry;

  const label = entry.purchaseButtonLabel ? entry.purchaseButtonLabel : 'Buy';

  return (
    <div className="PurchaseLinks">
      <div className="PurchaseLinks__links">
        {purchaseLinks.map((block) => (
          <div key={block.id} className="PurchaseLinks__block">
            {block.heading ? (
              <Heading block={block} />
            ) : (
              <Link block={block} label={label} entry={entry} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Heading = ({ block }) => (
  <h3 className="PurchaseLinks__heading">{block.heading}</h3>
);

const Link = ({ block, label, entry }) => {
  const logo = block.logo && block.logo.length > 0 ? block.logo[0] : null;

  return (
    <a
      href={block.linkUrl}
      className="PurchaseLinks__link"
      target="_blank"
      data-entry-id={entry.id}
      data-entry-title={entry.title}
      rel="noopener noreferrer"
    >
      {logo ? (
        <div className="PurchaseLinks__logo">
          <img
            src={logo.url}
            alt={block.label}
            className="PurchaseLinks__img"
          />
        </div>
      ) : (
        <div className="PurchaseLinks__label">{block.label}</div>
      )}
      <div className="PurchaseLinks__button">
        <div className="PurchaseLinks__buttonLabel">{label}</div>
      </div>
    </a>
  );
};

export default PurchaseLinks;
